<template>
  <div id="app">
    <router-view class="bootstrap-wrapper" />
  </div>
</template>

<script>
export default {
  name: 'App',
  beforeCreate() {
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
      console.log("%c It's a local server! ", 'background: #222; color: #bada55');
    } else {
      // if (location.protocol !== 'https:') { /* redirect to https */
      //   location.replace(`https:${location.href.substring(location.protocol.length)}`);
      // }
    }
  },
}
</script>

<style>
@import './has-tooltip.css';
@import url(//fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap);

#app {
  font-family: 'Prompt', sans-serif !important;
}

.card .card-body {
  font-family: 'Prompt', sans-serif !important;
}

.w-100 {
  width: 100% !important;
}

.text-center {
  align-items: center !important;
}

.cursor_pointer {
  cursor: pointer !important;
}

.swal-text {
  text-align: center !important;
}

.swal-footer {
  text-align: center !important;
}

.float-right {
  float: right !important;
}

.multiselect__tag {
  background: #3273dc !important;
}

.multiselect__tag-icon:after {
  color: #fff !important;
}

.multiselect__content {
  margin: 0 !important;
}

.show-mobile {
  display: none !important;
}

@media screen and (max-width: 767px) {

  /* (max-width: 600px) */
  .hidden-mobile {
    display: none !important;
  }

  .show-mobile {
    display: block !important;
  }
}

/* ................................................. */
.transform-none {
  text-transform: none !important;
}

.cut-text {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  /* display: none !important; */
  /* width: 160px;  */
  /* height: 1.2em;  */
}

.photo-album {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  /* height: 175px !important; */
}

.photo-cover {
  width: 100%;
  height: 375px !important;
  object-fit: cover !important;
}

@media screen and (max-width: 992px) {

  /* (max-width: 767px) */
  .photo-cover {
    height: 275px !important;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.show {
  display: block !important;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.avatar-3xl {
  width: 245px !important;
  height: 245px !important;
}

.blur {
  background-color: rgba(245, 245, 245, 1);
  opacity: .05;
}

.input-border {
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  padding: 0 10px !important;
}

.search-bar {
  width: 100%;
}

@media screen and (min-width: 767px) {
  .search-bar {
    width: 300px !important;
    float: right;
  }
}

.search-bar .input-group.input-group-dynamic .form-control,
.search-bar .input-group.input-group-dynamic .form-control:focus,
.search-bar .input-group.input-group-static .form-control,
.search-bar .input-group.input-group-static .form-control:focus {
  background-image: linear-gradient(0deg, #1b73e8 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #d2d2d2 1px, hsla(0, 0%, 82%, 0) 0);
}

.c-sliding-pagination__list-element {
  background: #323f5b !important;
}

.c-sliding-pagination__list-element--active {
  background: #3289ec !important;
}
</style>